@tailwind base;
@tailwind components;
@tailwind utilities;

.alert-enter {
  opacity: 0;
}

.alert-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.loader {
  border-top-color: rgba(52, 211, 153, 1);
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
